/** web_reset.cssを読み込む必要のないパスの一覧 */
export const WEB_RESET_NOT_REQUIRED_PATHS: readonly string[] = [
  '/apply/banklist',
  '/borrow/simulation',
  '/borrow/simulation/result',
  '/borrow/simulation/plan',
  '/faq',
  '/faq/apply',
  '/faq/borrow',
  '/faq/repay',
  '/faq/other',
] as const;
