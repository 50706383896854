<script setup lang="ts">
import { isError as isH3Error } from 'h3';

import { ClientBusinessError, createBusinessError } from '@/functions/error/views';
import {
  loadScript,
  loadScriptForAutoHeight,
  J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS,
  WEB_RESET_NOT_REQUIRED_PATHS,
} from '@/utils';

import { onErrorCaptured, createError, useRoute, ref, onMounted } from '#imports';

const handleOnError = (err: Error) => {
  // createErrorを用いて作成されたエラーは、再throwする。
  if (isH3Error(err)) {
    throw err;
  }

  if (err instanceof ClientBusinessError) {
    throw createBusinessError({ errorCode: err.errorCode });
  }

  // 想定外のランタイムエラーは、別途createErrorエラー情報を作成しなおす。
  throw createError({ ...err, fatal: true });
};

onErrorCaptured(handleOnError);

const autoHeightLoaded = ref(false);
const { path } = useRoute();

const webResetNotRequiredPaths = WEB_RESET_NOT_REQUIRED_PATHS.concat(
  WEB_RESET_NOT_REQUIRED_PATHS.map((path) => `${path}/`)
);

// lpサイトからの移行のため、d払いページのみweb_common.cssを読み込まない
if (!path.includes('/lp')) {
  await import('@/assets/css/web_common.css');
}

// 条件に応じてCSSを動的にインポート
if (webResetNotRequiredPaths.includes(path)) {
  import('@/assets/css/sitetop-web_common.css');
  import('@/assets/css/ress.css');
} else {
  import('@/assets/css/web_reset.css');
}

onMounted(() => {
  if (
    !path.includes('/app_detail') &&
    !path.includes('/bank_transfer/') &&
    !path.includes('/BankAccountTransferEnd.html')
  ) {
    const jQuery = loadScript('/js/jquery-3.6.0.min.js');

    jQuery.addEventListener('load', () => {
      loadScript('/js/web_common.js');
      loadScript('/js/set_inflow_source.js');

      const jQueryAutoHeightRequiredPaths = J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS.concat(
        J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS.map((path) => `${path}/`)
      );

      if (jQueryAutoHeightRequiredPaths.includes(path)) {
        const autoHeight = loadScript('/js/jQueryAutoHeight.js');

        autoHeight.addEventListener('load', () => {
          autoHeightLoaded.value = true;

          if (!path.includes('/campaign')) {
            loadScriptForAutoHeight(path);
          }
        });
      }
    });
  }
});
</script>

<template>
  <div>
    <NuxtPage :auto-height-loaded />
  </div>
</template>
