import { API_URL, HttpClient } from '@/utils';
import { GetMaintenanceResponse, MaintenancePattern } from '@/functions/maintenance';

/**
 *  該当する閉塞制御設定を取得する関数
 */
export const filterMaintenance = async ({ patterns }: { patterns: MaintenancePattern[] }) => {
  if (patterns.length === 0) return [];

  const httpClient = new HttpClient();
  const getMaintenanceResponse = await httpClient.get<GetMaintenanceResponse>(API_URL.GET_MAINTENANCE);
  const { currentSystemDateTime } = await httpClient.get<{ currentSystemDateTime: string }>(
    API_URL.GET_CURRENT_SYSTEM_DATE_TIME
  );

  /**
   * NOTE: AEMの日時を表すISO文字列は、UTCで返却される
   * 例：AEM上で日本時間2024/07/30 1:00-6:00と入力していたとしても、永続クエリの戻り値は2024/07/29 16:00-21:00
   */
  return maintenanceResponseFilter(getMaintenanceResponse, currentSystemDateTime, patterns);
};

/**
 * 指定のメンテナンス情報を返却する
 * @param response - メンテナンスレスポンス
 * @param currentSystemDateTime - 現在時刻
 * @param patterns - メンテナンス対象
 */
export const maintenanceResponseFilter = (
  response: GetMaintenanceResponse,
  currentSystemDateTime: string,
  patterns: MaintenancePattern[]
) => {
  return response
    .filter((config) => config.enabled)
    .filter((config) => {
      try {
        const [start, end] = [Date.parse(config.start), Date.parse(config.end)];
        const now = new Date(currentSystemDateTime).getTime();
        return start <= now && now <= end;
      } catch (e) {
        return false;
      }
    })
    .filter((config) => {
      const { pattern } = config;
      return patterns.includes(pattern);
    });
};
