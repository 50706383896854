/** jQueryAutoHeight.jsを読み込む必要があるパスの一覧 */
export const J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS: readonly string[] = [
  '/borrow/bank',
  '/borrow/d_payment',
  '/borrow/limit',
  '/campaign',
  '/repay/bank',
  '/repay/bullet',
  '/repay/d_payment',
] as const;

/**
 * JavaScriptファイルを読み込む
 * @param src - 読み込むスクリプトのパス
 * @returns HTMLScriptElement
 */
export const loadScript = (src: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', src);
  document.body.appendChild(script);
  return script;
};

/**
 * jQueryAutoHeightの発火に必要なJavaScriptファイルをパスに応じて読み込む
 * @param path - 対象のページのパス
 */
export const loadScriptForAutoHeight = (path: string) => {
  const filename = path.at(-1) === '/' ? path.slice(0, -1) : path;
  const src = `/js/autoHeight${filename}.js`;
  loadScript(src);
};
