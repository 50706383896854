import { ROUTES } from '@/enums';

import { defineNuxtRouteMiddleware } from '#imports';
import { navigateTo } from '#app';

/**
 * 金融機関からの戻り先URLが現行のURLが叩かれた際にURLとクエリの状態で遷移先を置換しNuxt上のURLに遷移する
 */
export default defineNuxtRouteMiddleware((to) => {
  // 現行FOCUSの金融機関からの戻り先URL
  const FOCUS_URL = '/BankAccountTransferEnd.html';
  if (to.path === FOCUS_URL) {
    const status = to.query.result;
    switch (status) {
      case 'OK':
        return navigateTo(ROUTES.BANK_TRANSFER_COMPLETE.path);
      case 'Error':
        return navigateTo(ROUTES.BANK_TRANSFER_FAILURE.path);
      case 'Cancel':
        return navigateTo(ROUTES.BANK_TRANSFER_INTERRUPTION.path);
    }
  }
});
