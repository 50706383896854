<script setup lang="ts">
import { Header } from '@/components/ui';
import { Footer } from '@/components/ui';
import { convertUrl } from '@/utils/convertUrl';
import { loadScript } from '@/utils';

import { onMounted, useHead } from '#imports';

useHead({
  title: '404 Not Found　お探しのページが見つかりません | dスマホローン',
  meta: [
    {
      name: 'ogDescription',
      content:
        'お客さまのお探しのページが見つかりません。お探しのページはURLが変更または削除された可能性があります。dスマホローンは、ドコモが提供するスマホ完結のローンサービスです。お申込みからご契約、お借入れ・ご返済もアプリまたはWebにて完結。書類の郵送や来店も不要です。',
    },
  ],
});

// bodyのpadding-topを計算するタイミングのため
onMounted(() => {
  const jQuery = loadScript('/js/jquery-3.6.0.min.js');

  jQuery.addEventListener('load', () => {
    loadScript('/js/web_common.js');
    loadScript('/js/set_inflow_source.js');
  });
});

await import('@/assets/css/web_common.css');
await import('@/assets/css/web_reset.css');

const D_CARD_CASHING =
  'ｄカードのキャッシングをご利用中のお客さまへの注意事項は<a class="arrow_icon arrow_icon02" href="/apply/#anc_02">こちら</a>';
</script>

<template>
  <Header />
  <div class="isp hbg_bg"></div>

  <main class="main">
    <section class="section01">
      <div class="section_inr01">
        <p class="title_lead04">404 Not Found</p>
        <h1 class="title_lv1"><span class="ask">お探しのページが</span><span class="ask">見つかりません</span></h1>
        <div class="tAc baseMt06">
          <div class="notice_area_img">
            <img src="/img/404_img_01.svg" alt="お探しのページが見つかりません" width="180" height="180" class="isPc" />
            <img src="/img/404_img_02.svg" alt="お探しのページが見つかりません" width="150" height="150" class="isSp" />
          </div>
          <div class="baseMt06 sptAl">
            <p class="textType01">お探しのページはURLが変更または削除された可能性があります。</p>
          </div>
          <ul class="listType01 sptAl">
            <li>ブックマークを登録されている場合は、お手数ですが設定の変更をお願いいたします。</li>
          </ul>
        </div>
        <div class="linkbtn01">
          <p class="btnType01"><a href="/">dスマホローンサイトトップへ</a></p>
        </div>
      </div>
    </section>
    <!-- /.section01 -->

    <section class="section02 bgRed">
      <div class="section_inr01">
        <p class="title_lv2 tAc">
          <span class="fcRed"><span class="ask">dスマホローンって</span><span class="ask">どんなサービス？</span></span>
        </p>
        <p class="textType01 sptAl pctAc">
          dスマホローンはドコモが提供するスマホ完結のカードレスローンです。<br />ドコモのスマホをお持ちでない方もお申込みいただけます。
        </p>
        <ul class="grit_link_area mt30">
          <li class="grit_item grit_item_01">
            <a class="grit_item_link pickup arrow_icon" href="/about/">
              <div class="grit_item_img">
                <img src="/img/img_38.svg" alt="dスマホローンとは" width="48" height="56" />
              </div>
              <p class="grit_item_txt"><span class="ask">dスマホローン</span><span class="ask">とは</span></p>
            </a>
          </li>
          <li class="grit_item grit_item_02">
            <a class="grit_item_link arrow_icon" href="/apply/">
              <div class="grit_item_img">
                <img src="/img/img_40.svg" alt="お申込みについて" width="50" height="50" />
              </div>
              <p class="grit_item_txt"><span class="ask">お申込みに</span><span class="ask">ついて</span></p>
            </a>
          </li>
          <li class="grit_item grit_item_03">
            <a class="grit_item_link arrow_icon" href="/borrow/">
              <div class="grit_item_img">
                <img src="/img/img_39.svg" alt="お借入れについて" width="52" height="54" />
              </div>
              <p class="grit_item_txt"><span class="ask">お借入れに</span><span class="ask">ついて</span></p>
            </a>
          </li>
          <li class="grit_item grit_item_04">
            <a class="grit_item_link arrow_icon" href="/repay/">
              <div class="grit_item_img">
                <img src="/img/img_41.svg" alt="ご返済について" width="52" height="54" />
              </div>
              <p class="grit_item_txt"><span class="ask">ご返済に</span><span class="ask">ついて</span></p>
            </a>
          </li>
        </ul>
      </div>
    </section>
    <!-- /.section02 -->
  </main>
  <!-- /.main -->

  <section class="section02"></section>
  <!-- /.section02 -->

  <section class="section02">
    <!--#include virtual="/focus/ssi/cv_area.html" -->
    <ul class="cv_card cv_btnArea">
      <li class="cv_card_item">
        <h2 class="title_lv2">新規お申込み</h2>
        <div class="cv_card_text_area">
          <p class="cv_card_text pctAl sptAc">
            <span class="ask"><span class="emphasis">お申込み</span>から<span class="emphasis">ご契約</span>まで</span
            ><span class="ask"><span class="emphasis">Web<span>で</span>完結！</span></span>
          </p>
        </div>
        <div class="cv_card_btn01">
          <p class="top_btnItem btnType02 btn_icon btn_icon01 cv_card_btn fwb">
            <a
              :href="`${convertUrl()}common/login/cushionWithUniversalLinkActivation?prompt=relogin&transitionSourceId=basic`"
              >今すぐお申込み<br /><span>（dアカウントでログイン）</span>
            </a>
          </p>
          <ul class="listType01 tAl mt10">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="D_CARD_CASHING"></li>
          </ul>
        </div>
      </li>
      <li class="cv_card_item">
        <h2 class="title_lv2">簡易シミュレーション</h2>
        <div class="cv_card_btn02">
          <p class="top_btnItem btnType03 btn_icon btn_icon04 cv_card_btn fwb">
            <a href="/apply/1second/"><span>お借入可能？優遇金利は何％？</span>簡単1秒診断</a>
          </p>
        </div>
        <div class="cv_card_btn02 mt10">
          <p class="top_btnItem btnType03 btn_icon btn_icon02 cv_card_btn fwb">
            <a href="/borrow/simulation/"
              ><span>毎月のご返済額はいくらぐらい？</span
              ><span class="fs18"><span class="ask">ご返済</span><span class="ask">シミュレーション</span></span></a
            >
          </p>
        </div>
      </li>
    </ul>
  </section>
  <!-- /.section02 -->
  <Footer />
</template>

<style lang="scss" scoped>
.main {
  padding-top: 21px;
}
</style>
